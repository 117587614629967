<template>
  <div class="loaded-device-detail">
    <a-card title="负载设备类型详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions :column="2" title="负载设备类型数据" bordered style="margin-top: 1%">
        <a-descriptions-item label="负载设备类型id"> {{ form.loadedDeviceTypeId }} </a-descriptions-item>
        <a-descriptions-item label="负载设备类型名称"> {{ form.name }} </a-descriptions-item>
        <a-descriptions-item label="负载设备类型类别">
          <span v-if="form.categories">
            {{ form.categories.map((category) => $constRepository.loadedDevice.CategoriesLabel[category]).join('、') }}
          </span>
          <span v-else> - </span>
        </a-descriptions-item>
        <a-descriptions-item label="负载设备类型详情页url"> {{ form.detailPage }} </a-descriptions-item>
        <a-descriptions-item label="发布区域">
          <span v-if="form.regions">
            {{ form.regions.map((region) => region.name).join('、') }}
          </span>
          <span v-else> - </span>
        </a-descriptions-item>
      </a-descriptions>
      <template v-if="productList.length > 0">
        <div class="loadDevice_title">关联物模型</div>
        <a-descriptions
          v-for="product in productList"
          :key="product.id"
          bordered
         :column="2"
          style="margin-top: 1%"
        >
          <a-descriptions-item label="物模型ID"> {{ product.productId }} </a-descriptions-item>
          <a-descriptions-item label="物模型名称"> {{ product.name }} </a-descriptions-item>
        </a-descriptions>
      </template>
      <template v-if="form.properties && form.properties.length > 0">
        <div class="loadDevice_title">负载设备类型属性项</div>
        <loaded-device-property-card class="property-card" :properties="form.properties" />
      </template>

      <div v-if="form.images && form.images.length > 0" class="loadDevice_title">负载设备类型图片数据</div>
      <a-card :tab-list="tabList" :active-tab-key="tabKey" @tabChange="(key) => (tabKey = key)">
        <div>
          <div style="margin-bottom: 10px">负载设备类型实体图:</div>
          <div class="clearfix">
            <a-upload
              v-if="form.images[tabKey - 1]"
              list-type="picture-card"
              :file-list="form.images[tabKey - 1].solidImages"
              :disabled="true"
              @preview="handlePreview"
              @change="handleChange"
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
            </a-modal>
          </div>
        </div>
        <div>
          <div style="margin-bottom: 10px">负载设备类型线框图:</div>
          <div class="clearfix">
            <a-upload
              v-if="form.images[tabKey - 1]"
              list-type="picture-card"
              :file-list="form.images[tabKey - 1].lineImages"
              :disabled="true"
              @preview="handlePreview"
              @change="handleChange"
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%;background-color: #e6e6e6;" :src="previewImage" />
            </a-modal>
          </div>
        </div>
      </a-card>
    </a-card>
    <a-card title="属性数据值类型" style="width: 80%; margin: 0 auto; margin-bottom: 2%">
      <vue-json-editor
        v-if="form.properties"
        v-model="form.properties"
        :showBtns="false"
        :mode="'code'"
        lang="zh"
        style="height: 800px"
      />
      <a-button type="primary" class="creator-btn" @click="$router.back()">返回</a-button>
    </a-card>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import lodash from 'lodash';
import LoadedDevicePropertyCard from './components/loadedDevicePropertyCard';

export default {
  name: 'LoadedDeviceDetail',
  components: { vueJsonEditor, LoadedDevicePropertyCard },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      await Promise.all([
        this.listLoadedDeviceTypeById(this.$route.params.id),
        this.listProducts(this.$route.params.id),
      ]);
    }
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      rules: {
        name: [{ required: true, message: '属性名称 不能为空', trigger: 'blur' }],
        enName: [{ required: true, message: '英文名 不能为空', trigger: 'blur' }],
      },
      previewVisible: false,
      previewImage: '',
      lineImages: [],
      form: {
        regionIdentifiers: [],
        propertyIds: [],
        images: [],
        regions: [],
      },
      tabList: [
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[this.$constRepository.loadedDevice.ImageTypes.AIHOME],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE
          ],
        },
        {
          key: `${this.$constRepository.loadedDevice.ImageTypes.NEXSMART}`,
          tab: this.$constRepository.loadedDevice.ImageTypeLabel[
            this.$constRepository.loadedDevice.ImageTypes.NEXSMART
          ],
        },
      ],
      tabKey: `${this.$constRepository.loadedDevice.ImageTypes.AIHOME}`,
      productList: [],
    };
  },
  methods: {
    async handleLanguage() {
      await this.listLoadedDeviceTypeById(this.$route.params.id);
    },
    async listLoadedDeviceTypeById(id) {
      const params = { loadedDeviceTypeId: id };
      const headers = {
        'accept-language': this.languageSelect,
      };
      const response = await this.$apiManager.loadedDeviceType.getLoadedDeviceTypeById(params, headers);
      this.form = response.data;
      // 填充images
      if (!this.form.images[0]) {
        this.form.images[0] = {
          type: this.$constRepository.loadedDevice.ImageTypes.AIHOME,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.form.images[1]) {
        this.form.images[1] = {
          type: this.$constRepository.loadedDevice.ImageTypes.SMARTSPACE,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      if (!this.form.images[2]) {
        this.form.images[2] = {
          type: this.$constRepository.loadedDevice.ImageTypes.NEXSMART,
          solidImages: [],
          lineImages: [],
          solidImageDownloadUrls: [],
          lineImageDownloadUrls: [],
        };
      }
      this.form.images.forEach((image, imageIndex) => {
        const originSolidImages = lodash.cloneDeep(image.solidImages);
        const originLineImages = lodash.cloneDeep(image.lineImages);
        this.form.images[imageIndex].solidImages.splice(0, this.form.images[imageIndex].solidImages.length);
        this.form.images[imageIndex].lineImages.splice(0, this.form.images[imageIndex].lineImages.length);
        image.solidImageDownloadUrls.forEach((url, index) => {
          const solidImage = {
            url,
            filePath: originSolidImages[index],
            name: originSolidImages[index],
            uid: -index,
          };
          this.form.images[imageIndex].solidImages.push(solidImage);
        });
        image.lineImageDownloadUrls.forEach((url, index) => {
          const lineImage = {
            url,
            filePath: originLineImages[index],
            name: originLineImages[index],
            uid: -index,
          };
          this.form.images[imageIndex].lineImages.push(lineImage);
        });
      });
    },
    async listProducts(id) {
      const params = {
        detail: true,
        unpaged: true,
        loadedDeviceTypeIds: [id],
      };
      const response = await this.$apiManager.product.getProducts(params);
      this.productList = response.data.elements;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      const imageFile = file;
      if (!imageFile.url && !imageFile.preview) {
        imageFile.preview = await this.getBase64(imageFile.originFileObj);
      }
      this.previewImage = imageFile.url || imageFile.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    cleanPropertyId() {
      this.form.id = '';
    },
    cancel() {
      this.$router.back();
    },
    onSubmit() {},
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.creator-btn {
  margin-top: 2%;
}
.loadDevice_title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.loaded-device-detail{
  /deep/ .ant-descriptions-row {
    width: 100%;
    word-break: break-all;
    .ant-descriptions-item-label {
      width: 170px;
    }
    .ant-descriptions-item-content {
      width:200px;
    }
  }
}
</style>
